// extracted by mini-css-extract-plugin
export var column = "LocationBrochureContact__column__QnVsE";
export var envelopeImage = "LocationBrochureContact__envelopeImage__K_z_N";
export var flex = "LocationBrochureContact__flex__NkGz7";
export var flexColumn = "LocationBrochureContact__flexColumn__ExZqY";
export var gap1 = "LocationBrochureContact__gap1__k0HsQ";
export var gap2 = "LocationBrochureContact__gap2__L8NFZ";
export var gap3 = "LocationBrochureContact__gap3___yk6M";
export var gap4 = "LocationBrochureContact__gap4__R_lRh";
export var gap5 = "LocationBrochureContact__gap5__JzriZ";
export var locationContactContainer = "LocationBrochureContact__locationContactContainer__wbjMJ";
export var locationContactEnvelope = "LocationBrochureContact__locationContactEnvelope__ZR_SP";
export var locationContactHeader = "LocationBrochureContact__locationContactHeader__sOBc9";
export var locationContactJobTitle = "LocationBrochureContact__locationContactJobTitle__YJcuC";
export var locationContactMessage = "LocationBrochureContact__locationContactMessage__Qr5wW";
export var locationContactMessageSub = "LocationBrochureContact__locationContactMessageSub__VOPN3";
export var locationContactName = "LocationBrochureContact__locationContactName__eLQfy";
export var locationContactPerson = "LocationBrochureContact__locationContactPerson__CNdCk";
export var locationContactPersonImage = "LocationBrochureContact__locationContactPersonImage__kqkSu";
export var locationContactSubmitted = "LocationBrochureContact__locationContactSubmitted__owGKV";
export var locationContactSubtitle = "LocationBrochureContact__locationContactSubtitle__B1qbU";
export var locationContactSummary = "LocationBrochureContact__locationContactSummary__JF4pL";
export var locationContactTitle = "LocationBrochureContact__locationContactTitle__VaKxO";
export var row = "LocationBrochureContact__row__Zbl4O";
export var salesManagerImage = "LocationBrochureContact__salesManagerImage__GeYOO";