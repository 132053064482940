// extracted by mini-css-extract-plugin
export var column = "LocationBrochureShowSidebar__column__FVqQC";
export var flex = "LocationBrochureShowSidebar__flex__zjP0w";
export var flexColumn = "LocationBrochureShowSidebar__flexColumn__DscO_";
export var gap1 = "LocationBrochureShowSidebar__gap1__hIm1h";
export var gap2 = "LocationBrochureShowSidebar__gap2__kQFns";
export var gap3 = "LocationBrochureShowSidebar__gap3__ScO81";
export var gap4 = "LocationBrochureShowSidebar__gap4__zPein";
export var gap5 = "LocationBrochureShowSidebar__gap5__y78Os";
export var imageContainer = "LocationBrochureShowSidebar__imageContainer__dlMZb";
export var locationDetail = "LocationBrochureShowSidebar__locationDetail__jkKN2";
export var locationSummary = "LocationBrochureShowSidebar__locationSummary__Jyuzn";
export var row = "LocationBrochureShowSidebar__row__jD3ZF";
export var sidebar = "LocationBrochureShowSidebar__sidebar__OVKjl";
export var summaryTitle = "LocationBrochureShowSidebar__summaryTitle__BHjWe";