// extracted by mini-css-extract-plugin
export var column = "LocationBrochureShow__column__aU6kk";
export var contactUsContainer = "LocationBrochureShow__contactUsContainer__EY6WV";
export var container = "LocationBrochureShow__container__hOx9U";
export var flex = "LocationBrochureShow__flex__Bvedp";
export var flexColumn = "LocationBrochureShow__flexColumn__BXFkr";
export var gap1 = "LocationBrochureShow__gap1__WoEbW";
export var gap2 = "LocationBrochureShow__gap2__ftCZB";
export var gap3 = "LocationBrochureShow__gap3__A44S8";
export var gap4 = "LocationBrochureShow__gap4__Os_Sk";
export var gap5 = "LocationBrochureShow__gap5__rFocw";
export var mainContentContainer = "LocationBrochureShow__mainContentContainer__Lgx3r";
export var row = "LocationBrochureShow__row__ZfHKt";
export var sidebarContainer = "LocationBrochureShow__sidebarContainer__IJj30";