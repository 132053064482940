// extracted by mini-css-extract-plugin
export var column = "LocationBrochureContactForm__column__vfKbx";
export var field = "LocationBrochureContactForm__field__oVIlb";
export var fieldInput = "LocationBrochureContactForm__fieldInput__axV5a";
export var fieldRow = "LocationBrochureContactForm__fieldRow__raSwO";
export var flex = "LocationBrochureContactForm__flex__BDQhp";
export var flexColumn = "LocationBrochureContactForm__flexColumn__WqUSk";
export var formContainer = "LocationBrochureContactForm__formContainer__t23wc";
export var gap1 = "LocationBrochureContactForm__gap1__Z32g7";
export var gap2 = "LocationBrochureContactForm__gap2__wpxAQ";
export var gap3 = "LocationBrochureContactForm__gap3__Q3XyG";
export var gap4 = "LocationBrochureContactForm__gap4__JnQxb";
export var gap5 = "LocationBrochureContactForm__gap5__v3_J2";
export var label = "LocationBrochureContactForm__label__BtevE";
export var required = "LocationBrochureContactForm__required__ok8cA";
export var row = "LocationBrochureContactForm__row__sMeNJ";
export var selectInput = "LocationBrochureContactForm__selectInput__IDlQU";