// extracted by mini-css-extract-plugin
export var breadcrumbs = "LocationBrochureShowContent__breadcrumbs__z669b";
export var column = "LocationBrochureShowContent__column__yxiVV";
export var content = "LocationBrochureShowContent__content__GYM4_";
export var flex = "LocationBrochureShowContent__flex__uxgoW";
export var flexColumn = "LocationBrochureShowContent__flexColumn__utMlH";
export var gap1 = "LocationBrochureShowContent__gap1__TS_Mr";
export var gap2 = "LocationBrochureShowContent__gap2__j_NBo";
export var gap3 = "LocationBrochureShowContent__gap3__kwcbx";
export var gap4 = "LocationBrochureShowContent__gap4__wINmU";
export var gap5 = "LocationBrochureShowContent__gap5__jTBj4";
export var row = "LocationBrochureShowContent__row__feX4n";
export var sectionTitle = "LocationBrochureShowContent__sectionTitle__pZtC3";
export var subtitle = "LocationBrochureShowContent__subtitle__U_s7I";
export var title = "LocationBrochureShowContent__title___sk8s";